import { render, staticRenderFns } from "./regencies-map-price.vue?vue&type=template&id=140fc5b1"
import script from "./regencies-map-price.vue?vue&type=script&lang=js"
export * from "./regencies-map-price.vue?vue&type=script&lang=js"
import style0 from "./regencies-map-price.vue?vue&type=style&index=0&id=140fc5b1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports